





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import moment from 'moment'
import variables from '@/shared/variables'
import assetsData from '@/assets/data/assets-data.json'
import apolloClient from '../../shared/services/ApolloCLientAPI'
import Confirmation from '@/shared/components/Confirmation.vue'
import { Active, KeyAssets, PII } from '@/shared/enum/assets-enum'
import assetsQueries from '@/shared/queries/assetsQueries'
import ControlAssessment from '../control-assessment/ControlAssessment.vue'
import { UserRole } from '@/shared/enum/general-enum'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'

export default defineComponent({
    name: 'AssetDetails',
    props: {
        entityDetails: {
            type: Object,
        },
        enableEditSaveNext: {},
    },
    components: {
        Confirmation,
        ControlAssessment,
        DocumentationLink,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const editable = ref(false)
        const { users } = useState(['users'])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const saveType = ref('')
        const employees = ref([])
        const keyAssetsDropdown = ref([])
        const statusDropdown = ref([])
        const ciTypeDropdown = ref([])
        const assetTypeDropdown = ref([])
        const availabilityDropdown = ref([])
        const integrityDropdown = ref([])
        const confidentialityDropdown = ref([])
        const piiDropdown = ref([])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const disablePISens = ref(false)
        const { role } = useState(['role'])
        const controlsList = ref([])
        const activeDropdown = ref([])
        const formData = ref({
            NAME: '',
            LOCATION: '',
            UNIT: '',
            OWNER: role.value === UserRole.EMPLOYEE ? userId : 0,
            KEY_ASSET: '',
            VERSION: '',
            STATUS: '',
            CI_TYPE: '',
            PII: '',
            PII_SENSITIVITY: '',
            PII_SENSITIVITY_EXPLANATION: '',
            FUNCTIONAL_OWNERSHIP: '',
            TECHNICAL_OWNERSHIP: '',
            SUPPLIER: '',
            LINK_TO_CONTRACT: '',
            CONTRACT_END_DATE: new Date(),
            ASSET_EXPLANATION: '',
            BIV: {
                B: '',
                I: '',
                V: '',
            },
            TYPE: '',
            DESCRIPTION: '',
            CONTROLS_LIST: [],
            ACTIVE: 'ACTIVE',
        })

        const errors = ref({
            NAME: { missingValue: false },
            OWNER: { missingValue: false, expire: false },
            TYPE: { missingValue: false },
            PII: { missingValue: false },
            PII_SENSITIVITY: { missingValue: false },
            DESCRIPTION: { missingValue: false },
            AVAILABILITY: { missingValue: false },
            INTEGRITY: { missingValue: false },
            CONFIDENTIALITY: { missingValue: false },
        })
        const labelInfomationOpName = ref(null)
        const labelInfomationOpLocation = ref(null)
        const labelInfomationOpUnit = ref(null)
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpOwner = ref(null)
        const labelInfomationOpKeyAsset = ref(null)
        const labelInfomationOpVersion = ref(null)
        const labelInfomationOpStatus = ref(null)
        const labelInfomationOpType = ref(null)
        const labelInfomationOpCiType = ref(null)
        const labelInfomationOpAvailability = ref(null)
        const labelInfomationOpIntegrity = ref(null)
        const labelInfomationOpConfidentiality = ref(null)
        const labelInfomationOpPii = ref(null)
        const labelInfomationOpPiiSensitivity = ref(null)
        const labelInfomationOpFuncOwnership = ref(null)
        const labelInfomationOpTechOwnership = ref(null)
        const labelInfomationOpSupplier = ref(null)
        const labelInfomationOpLinkToContract = ref(null)
        const labelInfomationOpContractEndDate = ref(null)
        const labelInfomationOpAssetExplanation = ref(null)
        const labelInfomationOpConnectedControls = ref(null)
        const labelInfomationOpDataClassification = ref(null)
        const labelInfomationOpActive = ref(null)

        //  validating form
        const validateForm = () => {
            // missingValue checks
            errors.value.NAME.missingValue = utils.validateFields(formData.value.NAME, 'string')

            errors.value.OWNER.missingValue = utils.validateFields(formData.value.OWNER, 'number')
            errors.value.TYPE.missingValue = utils.validateFields(formData.value.TYPE, 'string')
            errors.value.PII.missingValue = utils.validateFields(formData.value.PII, 'string')
            // pii sensitivity will be missingValue when PII is true(Yes/Ja)
            if (formData.value.PII) {
                errors.value.PII_SENSITIVITY.missingValue = utils.validateFields(
                    formData.value.PII_SENSITIVITY,
                    'string'
                )
            }

            errors.value.DESCRIPTION.missingValue = utils.validateFields(
                formData.value.DESCRIPTION,
                'string'
            )
            errors.value.AVAILABILITY.missingValue = utils.validateFields(
                formData.value.BIV.B,
                'string'
            )
            errors.value.INTEGRITY.missingValue = utils.validateFields(formData.value.BIV.I, 'string')
            errors.value.CONFIDENTIALITY.missingValue = utils.validateFields(
                formData.value.BIV.V,
                'string'
            )
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find((employee) => employee.id === formData.value.OWNER)
            if (owner) errors.value.OWNER.expire = owner.userExpired
        }

        // convert keys in to name values
        const convertKeyToNames = (key, data) => {
            const name = utils.keyToName(data, key)
            return t(name, language.value)
        }

        const setDropdownValues = () => {
            // set dropdown values for key assets

            keyAssetsDropdown.value = []
            assetsData.KEY_ASSETS.map((asset) => {
                keyAssetsDropdown.value.push({
                    value: t(asset.VALUE, language.value),
                    key: asset.KEY,
                })
            })

            // owner list should only contain the users with role as employee
            employees.value = []
            users.value.map((user) => {
                // get user loggedin organisation (current org)
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get users who are available in current organisation with 'Employee' role
                const filteredUsers =
                    currentOrganisation[0] &&
                    !!currentOrganisation[0].roles.find(
                        (role) => role.name === UserRole.EMPLOYEE
                    ) &&
                    user.userExpired !== true
                if (filteredUsers) {
                    employees.value.push(user)
                }
            })

            // set dropdown values for asset status

            statusDropdown.value = [{ key: 'EMPTY', value: '-' }]
            assetsData.STATUS.map((status) => {
                statusDropdown.value.push({
                    value: t(status.VALUE, language.value),
                    key: status.KEY,
                })
            })

            // set dropdown values for ci type

            ciTypeDropdown.value = [{ key: 'EMPTY', value: '-' }]
            assetsData.CI_TYPE.map((ciType) => {
                ciTypeDropdown.value.push({
                    value: t(ciType.VALUE, language.value),
                    key: ciType.KEY,
                })
            })

            // set dropdown values for asset type

            assetTypeDropdown.value = []
            assetsData.ASSET_TYPE.map((type) => {
                assetTypeDropdown.value.push({
                    value: t(type.VALUE, language.value),
                    key: type.KEY,
                })
            })

            // set dropdown values for availability

            availabilityDropdown.value = []
            assetsData.AVAILABILITY.map((availability) => {
                availabilityDropdown.value.push({
                    value: t(availability.VALUE, language.value),
                    key: availability.KEY,
                })
            })

            // set dropdown values for integrity

            integrityDropdown.value = []
            assetsData.INTEGRITY.map((integrity) => {
                integrityDropdown.value.push({
                    value: t(integrity.VALUE, language.value),
                    key: integrity.KEY,
                })
            })

            // set dropdown values for confidentiality

            confidentialityDropdown.value = []
            assetsData.CONFIDENTIALITY.map((confidentiality) => {
                confidentialityDropdown.value.push({
                    value: t(confidentiality.VALUE, language.value),
                    key: confidentiality.KEY,
                })
            })

            // set dropdown values for pii

            piiDropdown.value = []
            assetsData.PII.map((pii) => {
                piiDropdown.value.push({
                    value: t(pii.VALUE, language.value),
                    key: pii.KEY,
                })
            })

            // set dropdown values for active

            activeDropdown.value = []

            assetsData.ACTIVE.map((active) => {
                activeDropdown.value.push({
                    value: t(active.VALUE, language.value),
                    key: active.KEY,
                })
            })
        }

        /* PII is No, then Pii_sens is disabled and pii_sens_exp disabled
            PII is yes, then Pii_sense is enabled and Pii_send_exp is enabled */
        const onPIchange = () => {
            if (formData.value.PII === PII.true) {
                disablePISens.value = false
            } else {
                disablePISens.value = true
                formData.value.PII_SENSITIVITY = PII.false
                formData.value.PII_SENSITIVITY_EXPLANATION = ''
            }
        }

        // set data for form
        const setFormData = () => {
            const assetDetails = props.entityDetails
            formData.value = {
                NAME: assetDetails.name,
                LOCATION: assetDetails.location,
                UNIT: assetDetails.unit,
                // if the logged in user's role is employee set default value as logged in user
                OWNER:
                    assetDetails.owner.id !== 5
                        ? assetDetails.owner.id
                        : role.value === UserRole.EMPLOYEE
                        ? userId
                        : 0,
                KEY_ASSET: assetDetails.keyAsset ? KeyAssets.true : KeyAssets.false,
                VERSION: assetDetails.version,
                STATUS: assetDetails.status,
                CI_TYPE: assetDetails.ciType,
                PII: assetDetails.pii ? PII.true : PII.false,
                PII_SENSITIVITY: assetDetails.piiSensitive ? PII.true : PII.false,
                PII_SENSITIVITY_EXPLANATION: assetDetails.piiSensitiveExplanation,
                FUNCTIONAL_OWNERSHIP: assetDetails.functionalOwnership,
                TECHNICAL_OWNERSHIP: assetDetails.technicalOwnership,
                SUPPLIER: assetDetails.supplier,
                LINK_TO_CONTRACT: assetDetails.linkToContract,
                CONTRACT_END_DATE:
                    assetDetails.contractEndDate && moment(assetDetails.contractEndDate).toDate(),
                ASSET_EXPLANATION: assetDetails.assetExplanation,
                BIV: {
                    B: assetDetails.availability,
                    I: assetDetails.integrity,
                    V: assetDetails.confidentiality,
                },
                TYPE: assetDetails.assetType,
                DESCRIPTION: assetDetails.description,
                CONTROLS_LIST: assetDetails.controls.map((control) => control.id),
                ACTIVE: Active[assetDetails.active],
            }

            onPIchange()
        }

        // get control list
        const getControls = async () => {
            let result
            try {
                result = await apolloClient.getGraphqlData(assetsQueries.CONTROL_QUERY)
            } catch (err) {
                throw Error('Error while retrieving controls')
            }

            if (result) {
                const controls = [...result.data.controls.items]

                // format controls data
                const formattedControls = []
                controls.map((control) => {
                    const controlCopy = Object.create(control)
                    // make the label unique
                    controlCopy.label =
                        control.refId + ' ' + control.controlNumber + ' ' + control.topic
                    controlCopy.fnorm = control.originNorm && control.originNorm.name
                    formattedControls.push(controlCopy)
                })
                controlsList.value = formattedControls
            }
        }

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.entityDetails.no, type })
        }

        // enable edit fields
        const enableEdit = () => {
            editable.value = true
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false

            if (props.entityDetails) {
                setFormData()
            } else {
                emit('close-create-new')
            }
        }

        // save assets data
        const save = (type) => {
            // validate form
            validateForm()
            // check if the form has errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            if (props.entityDetails) {
                emit('save-asset', {
                    id: props.entityDetails.id,
                    formData: formData.value,
                    type,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-asset', {
                    formData: formData.value,
                })
            }
        }
        // open save confirmation
        const openSaveConfirmation = (type) => {
            saveType.value = type
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(type)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        const getAssets = () => {
            emit('get-assets')
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return editable.value || !props.entityDetails
        })

        // watch for language chaneges and update labels
        watch(
            language,
            () => {
                setDropdownValues()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            await getControls()
            if (props.entityDetails) {
                setFormData()
            }
        })

        return {
            t,
            language,
            utils,
            convertKeyToNames,
            goToRecord,
            editable,
            enableEdit,
            moment,
            formData,
            cancelEdit,
            controlsList,
            save,
            role,
            variables,
            displaySaveConfirmation,
            displayCancelConfirmation,
            saveType,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            employees,
            keyAssetsDropdown,
            statusDropdown,
            ciTypeDropdown,
            assetTypeDropdown,
            availabilityDropdown,
            integrityDropdown,
            confidentialityDropdown,
            piiDropdown,
            assetsData,
            KeyAssets,
            PII,
            disablePISens,
            onPIchange,
            getAssets,
            errors,
            toggleInformation,
            labelInfomationOpName,
            labelInfomationOpLocation,
            labelInfomationOpUnit,
            labelInfomationOpDescription,
            labelInfomationOpOwner,
            labelInfomationOpKeyAsset,
            labelInfomationOpVersion,
            labelInfomationOpStatus,
            labelInfomationOpType,
            labelInfomationOpCiType,
            labelInfomationOpAvailability,
            labelInfomationOpIntegrity,
            labelInfomationOpConfidentiality,
            labelInfomationOpPii,
            labelInfomationOpPiiSensitivity,
            labelInfomationOpFuncOwnership,
            labelInfomationOpTechOwnership,
            labelInfomationOpSupplier,
            labelInfomationOpLinkToContract,
            labelInfomationOpContractEndDate,
            labelInfomationOpAssetExplanation,
            labelInfomationOpConnectedControls,
            labelInfomationOpDataClassification,
            labelInfomationOpActive,
            UserRole,
            activeDropdown,
            isEditableMode
        }
    },
})
