// status of assessments/ action plan
export enum KeyAssets {
    true = 'TRUE',
    false = 'FALSE',
}

export enum PII {
    true = 'YES',
    false = 'NO',
}

export enum Active{
    ACTIVE='ACTIVE',
    INACTIVE='INACTIVE'
}