

















































































import { defineComponent, onMounted, ref, Ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import AssetControlAssessment from '@/components/asset-control assessments/AssetControlAssessment.vue'
import assetsQueries from '@/shared/queries/assetsQueries'
import router from '@/router'
import apolloClient from '../../shared/services/ApolloCLientAPI'
import { UserRole } from '@/shared/enum/general-enum'
import ConfirmMessage from '@/shared/components/ConfirmMessage.vue'

export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        accessedFrom: {
            type: String,
        },
    },
    components: {
        AssetControlAssessment,
        ConfirmMessage,
    },
    setup(props, { emit }) {
        const displayAssetControlAssessment = ref(false)
        const controlId = ref(null)
        const assetId = ref(null)
        const assessmentId = ref(null)
        const newAssessmentSourceId: Ref<number> = ref(null)
        const { t } = useI18n()
        const { language } = useState(['language'])
        const confirm = ref(null)
        const { role } = useState(['role'])
        const controlsWithACassessments = ref([])
        const originComponents = ref({
            controlList: 'Controls',
        })
        const controlList = ref([])
        const routedFrom = ref('')
        const selectedControlDetails = ref(null)

        const createNewAssessment = (controlWithAssessments) => {
            controlId.value = controlWithAssessments.controlId
            assetId.value = props.entityDetails.id
            const latestAssessmentId = controlWithAssessments.assessments?.[0]?.id

            const createAssessmentFromLatest = () => {
                newAssessmentSourceId.value = latestAssessmentId
                displayAssetControlAssessment.value = true
            }
            const createEmptyAssessment = () => {
                newAssessmentSourceId.value = null
                displayAssetControlAssessment.value = true
            }

            if (latestAssessmentId) {
                confirm.value.confirm(
                    'CONFIRMATION_POPUP_USE_PREVIOUS_ASSESSMENT_RESULTS',
                    createAssessmentFromLatest,
                    createEmptyAssessment
                )
            } else {
                createEmptyAssessment()
            }
        }

        // get filtered assessment data for the selected asset via control
        const filterAssessmentWithAsset = () => {
            const controls = controlList.value
            controlsWithACassessments.value = []
            // filter each assessment of controls by asset id
            for (const item in controls) {
                const filtered = controls[item].assetControlAssessments.filter(
                    (val) => val.asset.id === props.entityDetails.id
                )
                // push filtered assessment data
                controlsWithACassessments.value.push({
                    controlId: controls[item].id,
                    controlNumber: controls[item].controlNumber,
                    assessments: filtered,
                    topic: controls[item].topic,
                    norm: controls[item].originNorm && controls[item].originNorm.name,
                })
            }
        }

        // get list of assets connected to selected control
        const getControlList = async () => {
            const controlsQuery = `
                query{
                    controls( controlFilters: { assetId:[${props.entityDetails.id}] }) {
                        items{
                            ${assetsQueries.CONNECTED_CONTROLS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(controlsQuery)
            } catch (err) {
                throw Error('Error while retrieving controls')
            }

            if (result) {
                // Copy array to be able to sort it in place
                const controls = [...result.data.controls.items]
                // sort by id (ascending)
                controls.sort((a, b) => (a.id > b.id ? 1 : -1))
                // updating assets array with additional fields
                controlList.value = controls
                filterAssessmentWithAsset()
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close assessment sidebar if it's open
                    if (displayAssetControlAssessment.value) {
                        displayAssetControlAssessment.value = false
                    }
                }
            })
        }

        // call refreshed data
        const updateRecords = async () => {
            // fetch updated assets list
            await getControlList()
            // fetch asset control assessments lists connected to control
            emit('refresh-assessments')
            // refresh asset details
            emit('post-assessment-update', { no: props.entityDetails.no, type: '' })
        }

        //  watch route path for decide which type of component we are on.
        watch(
            () => router.app.$route,
            () => {
                routedFrom.value = router.app.$route.name
            },
            {
                immediate: true,
            }
        )

        //  watch for entityDetails changes
        watch(
            () => Object.assign({}, props.entityDetails),
            () => {
                filterAssessmentWithAsset()
            },
            { immediate: true }
        )

        onMounted(() => {
            closeSidebarEsc()
            getControlList()
        })
        return {
            t,
            language,
            confirm,
            displayAssetControlAssessment,
            newAssessmentSourceId,
            createNewAssessment,
            controlId,
            assetId,
            originComponents,
            routedFrom,
            controlsWithACassessments,
            assessmentId,
            updateRecords,
            selectedControlDetails,
            role,
            UserRole,
        }
    },
})
