























































































































































import { defineComponent, onMounted, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '../../shared/mixins/helpers'
import { AssetsDTO } from '@/dto/backend-response/assetsDTO'
import Table from '@/shared/components/Table.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import assetsQuery from '@/shared/queries/assetsQueries'
import variables from '@/shared/variables'
import CreateAsset from '@/components/assets/CreateAsset.vue'
import AssetOperations from '@/components/assets/AssetOperations.vue'
import { Active, KeyAssets } from '@/shared/enum/assets-enum'
import { PII } from '@/shared/enum/assets-enum'
import assetsData from '@/assets/data/assets-data.json'
import { UserRole } from '@/shared/enum/general-enum'
import Confirmation from '@/shared/components/Confirmation.vue'
import { exportTable } from '@/shared/services/TableExport'

export default defineComponent({
    components: { Table, CreateAsset, AssetOperations, Confirmation },
    props: {
        idfilter: {
            type: Number,
        },
    },
    setup(props) {
        const assetsList = ref([])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { menuOpen } = useState(['menuOpen'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const expandedHeaderName = ref('')
        const toast = useToast()
        const loading = ref(false)
        const infomationOp = ref(null)
        const { role } = useState(['role'])
        const displayCreateAssets = ref(false)
        const visibleAssetDetails = ref(false)
        const assetDetails = ref(null)
        const visibleLeft = ref(false)
        const tableCmp = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const totalPages = ref(0)
        const displayDeleteConfirmation = ref(false)

        // set table headers
        const setTableHeaders = (language: string) => {
            tableHeaders.value = [
                {
                    header: t('ASSETS_TABLE_COLUMN_NO', language),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('ASSETS_TABLE_COLUMN_NAME', language),
                    sort: true,
                    fieldName: 'name',
                    info: false,
                    infoContent: t('ASSETS_TABLE_COLUMN_NAME_INFO', language),
                    limit: 200,
                    style: 'width: 400px',
                },
                {
                    header: t('ASSETS_TABLE_COLUMN_DESCRIPTION', language),
                    sort: true,
                    fieldName: 'description',
                    limit:200,
                    info: false,
                    infoContent: t('ASSETS_TABLE_COLUMN_DESCRIPTION_INFO', language),
                },
                {
                    header: t('ASSETS_TABLE_COLUMN_TYPE', language),
                    sort: true,
                    fieldName: 'type',
                    info: false,
                    infoContent: t('ASSETS_TABLE_COLUMN_TYPE_INFO', language),
                },
                {
                    header: t('ASSETS_TABLE_COLUMN_KEYASSET', language),
                    sort: true,
                    fieldName: 'fkeyAsset',
                    info: false,
                    infoContent: t('ASSETS_TABLE_COLUMN_KEYASSET_INFO', language),
                },
                {
                    header: t('ASSETS_TABLE_COLUMN_OWNER', language),
                    sort: true,
                    fieldName: 'fowner',
                    info: false,
                    infoContent: t('ASSETS_TABLE_COLUMN_OWNER_INFO', language),
                },
            ]
        }

        // convert keys in to name values
        const convertKeyToNames = (key, data) => {
            const bivName = utils.keyToName(data, key)
            return t(bivName, language.value)
        }

        // updating assetsList array with additional fields
        const toTableFormat = (tempResultData: AssetsDTO[]) => {
            assetsList.value = []
            tempResultData.map((asset, index) => {
                let assetCopy = {
                    no: 0,
                    color: '',
                    type: null,
                    fowner: '',
                    fkeyAsset: null,
                    fpii: null,
                    fpiiSensitive: null,
                    info: '',
                    infoKey: '',
                    alertColorName: '',
                    refId: '',
                    name: '',
                    description:'',
                }
                assetCopy = Object.create(asset) // creating a copy of asset object
                assetCopy.no = utils.padLeft('000', index + 1) // creating no
                assetCopy.color = utils.getAlertInfo('', asset).color
                assetCopy.info = utils.getAlertInfo('', asset).info
                assetCopy.infoKey = utils.getAlertInfo('', asset).infoKey
                assetCopy.alertColorName = utils.getAlertInfo('', asset).alertColorName
                assetCopy.type = convertKeyToNames(asset.assetType, assetsData.ASSET_TYPE)
                assetCopy.fowner = asset.owner.firstName + ' ' + asset.owner.lastName
                assetCopy.fkeyAsset = asset.keyAsset
                    ? convertKeyToNames(KeyAssets.true, assetsData.KEY_ASSETS)
                    : convertKeyToNames(KeyAssets.false, assetsData.KEY_ASSETS)
                assetCopy.fpii = asset.pii
                    ? convertKeyToNames(PII.true, assetsData.PII)
                    : convertKeyToNames(PII.false, assetsData.PII)
                assetCopy.fpiiSensitive = asset.piiSensitive
                    ? convertKeyToNames(PII.true, assetsData.PII)
                    : convertKeyToNames(PII.false, assetsData.PII)
                assetCopy.refId = asset.refId,
                assetCopy.name = asset.name,
                assetCopy.description = asset.description
                assetsList.value.push(assetCopy)
            })
        }

        // get all assets
        const getAssets = async () => {
            loading.value = true
            const getAssetsQuery = `
                query{
                    assets(perPage: ${variables.LIST_ITEMS_PER_PAGE}){
                        pageInfo{
                            totalPages
                            totalCount
                        }
                        items{
                            ${assetsQuery.ASSETS_LIST}
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getAssetsQuery)
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error('Error while retrieving assets')
            }
            if (result) {
                // Copy array to be able to sort it in place
                const assets = [...result.data.assets.items]
                totalPages.value = result.data.assets.pageInfo.totalPages
                filteredRecordCount.value = result.data.assets.pageInfo.totalCount
                // sort by id (ascending)
                assets.sort((a, b) => (a.refId > b.refId ? 1 : -1))
                // updating assets array with additional fields
                toTableFormat(assets)
            }
        }

        const getFormatedAssetDetails = () => {
            // set alert color and info
            assetDetails.value.color = utils.getAlertInfo('', assetDetails.value).color
            assetDetails.value.info = utils.getAlertInfo('', assetDetails.value).info
            assetDetails.value.infoKey = utils.getAlertInfo('', assetDetails.value).infoKey
            assetDetails.value.no = assetsList.value.find(
                (val) => val.id === assetDetails.value.id
            ).no
            assetDetails.value.lastRecordNo =
                tableCmp.value && utils.getLastRecord(tableCmp.value.copyOfTableData)
            assetDetails.value.firstRecordNo =
                tableCmp.value && tableCmp.value.copyOfTableData[0].no
        }

        // get asset by id
        /* `assetDetails.value = null` has to take out from `getAsset(id)` function, in order to avoid component
        recreating in certain places as per the function requirement.
         */
        const getAsset = async (id: number) => {
            displayCreateAssets.value = false
            const getAssetQuery = `
                query {
                    asset(id: ${id}) {
                        ${assetsQuery.ASSET_DETAILS}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getAssetQuery)
            } catch {
                throw Error('Error while retrieving asset data')
            }

            if (result) {
                assetDetails.value = result.data.asset
                getFormatedAssetDetails()
            }
        }

        // go to asset record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `assetsList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                assetDetails.value = null
                await getAsset(record.id)
            }
        }

        // update list after assessment is updated
        const updateListPostAssessment = async (params) => {
            await getAssets()
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`. 
            `assetsList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                await getAsset(record.id)
            }
        }

        // save while table list is sorted
        const sortableListSave = () => {
            // if the table is sorted (`sortedField` has a value)
            if (tableCmp.value.sortedField) {
                // Based on the sorted type(asc/desc), sort the refreshed list after saving
                if (tableCmp.value.sortToggle === 'up') {
                    assetsList.value.sort((a, b) =>
                        a[tableCmp.value.sortedField] < b[tableCmp.value.sortedField] ? 1 : -1
                    )
                } else {
                    assetsList.value.sort((a, b) =>
                        b[tableCmp.value.sortedField] < a[tableCmp.value.sortedField] ? 1 : -1
                    )
                }
            }
        }
        //  asset save (edit and create)
        const saveAsset = async (params) => {
            const assetFormData = params.formData
            let mutationQuery

            if (!assetDetails.value) {
                mutationQuery = `mutation ($input: AssetInput!) {
                        createAsset(input: $input) {
                            status
                            error
                        }
                    }`
            } else {
                mutationQuery = `mutation ($input: AssetInput!) {
                        updateAsset(id: ${params.id}, input: $input) {
                            status
                            error
                        }
                    }`
            }

            const input = {
                assetType: assetFormData.TYPE,
                name: assetFormData.NAME,
                location: assetFormData.LOCATION,
                unit: assetFormData.UNIT,
                owner: assetFormData.OWNER,
                keyAsset: assetFormData.KEY_ASSET === KeyAssets.true ? true : false,
                version: assetFormData.VERSION,
                status: assetFormData.STATUS === 'EMPTY' ? null : assetFormData.STATUS,
                ciType: assetFormData.CI_TYPE === 'EMPTY' ? null : assetFormData.CI_TYPE,
                availability: assetFormData.BIV.B,
                integrity: assetFormData.BIV.I,
                confidentiality: assetFormData.BIV.V,
                pii: assetFormData.PII === PII.true ? true : false,
                piiSensitive: assetFormData.PII_SENSITIVITY === PII.true ? true : false,
                piiSensitiveExplanation: assetFormData.PII_SENSITIVITY_EXPLANATION,
                functionalOwnership: assetFormData.FUNCTIONAL_OWNERSHIP,
                technicalOwnership: assetFormData.TECHNICAL_OWNERSHIP,
                supplier: assetFormData.SUPPLIER,
                linkToContract: assetFormData.LINK_TO_CONTRACT,
                contractEndDate: assetFormData.CONTRACT_END_DATE,
                assetExplanation: assetFormData.ASSET_EXPLANATION,
                description: assetFormData.DESCRIPTION,
                controls: assetFormData.CONTROLS_LIST,
                active: Active[assetFormData.ACTIVE],
            }
            let result
            try {
                // create/update asset api call
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('ASSET_SAVE_ERROR_MESSAGE', language.value))
                throw Error('Error while saving asset')
            }
            if (result.data.createAsset && result.data.createAsset.status) {
                toast.success(t('ASSET_SAVE_SUCESS_MESSAGE', language.value))
                await getAssets()
                displayCreateAssets.value = false
            } else if (result.data.updateAsset && result.data.updateAsset.status) {
                await getAssets()
                sortableListSave()
                // if user clicks on save and next button, post record save, it should go to next record
                if (params.type === 'save-next') {
                    await goToRecord({ no: params.no, type: 'next' })
                } else {
                    assetDetails.value = null
                    await getAsset(params.id)
                }
                toast.success(t('ASSET_SAVE_SUCESS_MESSAGE', language.value))
            } else {
                toast.error(t('ASSET_SAVE_ERROR_MESSAGE', language.value))
            }
        }

        // remove removed item from list array
        const removeRecord = (id: number) => {
            assetsList.value.splice(
                assetsList.value.findIndex((item) => item.id === id),
                1
            )
        }

        // delete asset
        const deleteAsset = async () => {
            const deleteQuery = `
                    mutation{
                        deleteAsset(id: ${assetDetails.value.id}){
                            status
                            error
                        }
                    }
                    `
            // approve data api call
            let result
            try {
                result = await apolloClient.updateGraphqlData(deleteQuery, null)
            } catch (err) {
                displayDeleteConfirmation.value = false
                toast.error(t('ASSET_DELETE_FAIL_MESSAGE', language.value))
                throw Error('Error while deleting asset')
            }

            if (result.data.deleteAsset.status) {
                displayDeleteConfirmation.value = false
                visibleAssetDetails.value = false
                removeRecord(assetDetails.value.id)
                toast.success(t('ASSET_DELETE_SUCCESS_MESSAGE', language.value))
            } else toast.error(t('ASSET_DELETE_FAIL_MESSAGE', language.value))
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        const openAssetDetailsPopup = async (id) => {
            visibleAssetDetails.value = true
            assetDetails.value = null
            await getAsset(id)
        }

        const exportAllAssets = async () => {
            loading.value = true
            const date = new Date().toLocaleDateString('nl')
            const fileName = `perium-assets-${date}`
            try {                
                await exportTable(
                    fileName,
                    tableHeaders.value,
                    assetsList.value
                )
                toast.success(t('ASSET_EXPORT_SUCCESS_TOAST'))
            } catch (err) {
                toast.error(t('ASSET_EXPORT_FAILED_TOAST'))
                // Re-throw error for Sentry
                // Cast Error to any because old typescript does not know about
                // the options parameter. Can be removed once typescript is updated.
                throw (Error as any)('Error while exporting assets', {
                    cause: err,
                })
            } finally {
                loading.value = false
            }
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    const seondSidebar = document.querySelector('.second-sidebar')
                    //close view details entity if it's open and assessment(second side bar) is not open
                    if (visibleAssetDetails.value && !seondSidebar) {
                        visibleAssetDetails.value = false
                    }
                    // close create new entity if it's open
                    if (displayCreateAssets.value) {
                        displayCreateAssets.value = false
                    }
                }
            })
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, (newValue: string) => {
            setTableHeaders(newValue)
            if (assetsList.value) {
                toTableFormat(assetsList.value)
            }
        })

        onMounted(async () => {
            closeSidebarEsc()
            // when navigating from control details page, open selected row details
            if (props.idfilter) {
                await getAssets()
                // make row active
                const rowId = assetsList.value.find((val) => val.id === props.idfilter).no
                tableCmp.value.activeRow(rowId)
                // open details page
                openAssetDetailsPopup(props.idfilter)
            } else {
                await getAssets()
            }

            setTableHeaders(language.value)
        })

        return {
            assetsList,
            getAsset,
            t,
            language,
            tableHeaders,
            expandedHeaderName,
            loading,
            infomationOp,
            toggleInformation,
            displayCreateAssets,
            getAssets,
            role,
            variables,
            openAssetDetailsPopup,
            visibleAssetDetails,
            assetDetails,
            goToRecord,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            tableCmp,
            saveAsset,
            viewFilterSection,
            setCount,
            filterCount,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            assetsData,
            UserRole,
            totalPages,
            updateListPostAssessment,
            displayDeleteConfirmation,
            deleteAsset,
            exportAllAssets,
        }
    },
})
